// src/components/LoginModal.tsx
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const LoginModal: React.FC<LoginModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Box sx={style}>
        <Typography id="login-modal-title" variant="h6" component="h2">
          Log in
        </Typography>
        <Typography id="login-modal-description" sx={{ mt: 2 }}>
          Choose your login method:
        </Typography>
        <Button variant="contained" sx={{ mt: 2, mb: 1, width: '100%' }} onClick={() => { /* Implement Twitch login logic */ }}>
          Login with Twitch
        </Button>
        <Button variant="contained" sx={{ mb: 1, width: '100%' }} onClick={() => { /* Implement Discord login logic */ }}>
          Login with Discord
        </Button>
        <Button variant="contained" sx={{ width: '100%' }} onClick={() => { /* Implement email login logic */ }}>
          Login with Email
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
