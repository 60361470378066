import { Grid, Box, Typography, Button } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { EverbloomPost, EverbloomPostType } from '../types/EverbloomUpdateEntry';
import EverbloomUpdateEntryComponent from '../components/EverbloomUpdateEntry';
import { Newspaper } from '@mui/icons-material';

const WhatsNewSection: React.FC = () => {
    const newsItems: EverbloomPost[] = [
        {
            post_id: 1,
            post_title: "Echo Version 0.1 Launched",
            post_category: "Announcements",
            post_content: "The AI interface which connects discord and twitch is live.",
            post_published_date: new Date('22/04/2024'),
            post_imageUrl: "/assets/posts/post_echo2.jpg",
            post_published: true,
            post_slug: '',
            post_type: EverbloomPostType.Latest,
            post_is_discussion: false,
            post_is_reactable: false,
            post_author_id: 0,
            post_thumbUrl: '',
            post_is_official: true
        },
        {
            post_id: 2,
            post_title: "New wardrobe updates",
            post_category: "Updates",
            post_content: "New features are now live, including more ways to earn rewards!",
            post_published_date: new Date('20/04/2024'),
            post_imageUrl: "/assets/posts/post_customisation1.jpg",
            post_published: true,
            post_slug: '',
            post_type: EverbloomPostType.Latest,
            post_is_discussion: false,
            post_is_reactable: false,
            post_author_id: 0,
            post_thumbUrl: '',
            post_is_official: true
        },
        {
            post_id: 3,
            post_title: "Creators Needed",
            post_category: "Announcements",
            post_content: "We are looking for twitch streamers to help develop our creator tools and become part of the Bloomie universe.",
            post_published_date: new Date('13/04/2024'),
            post_imageUrl: "/assets/posts/post_creators.jpg",
            post_published: true,
            post_slug: '',
            post_type: EverbloomPostType.Latest,
            post_is_discussion: false,
            post_is_reactable: false,
            post_author_id: 0,
            post_thumbUrl: '',
            post_is_official: true
        }
    ];

    return (
        <Box component="section" id="whatsNew" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant="h3" sx={{ textAlign: 'center', m: 6, mb: 4, color: 'primary.main' }}>
                    Latest News
                </Typography>
                <Grid container spacing={4} padding={4}>
                    {newsItems.map((item, index) => (
                        <Grid item xs={12} md={4} key={item.post_id}>
                            <EverbloomUpdateEntryComponent entry={item} />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center', // Centers the buttons horizontally
                    mt: 0, // Adds margin top for spacing from text above
                    mb: 4,
                }}>

                    <Button href="/community/latest" variant="contained" sx={{
                        margin: 2,
                        padding: '8px 15px',
                        width: '180px',
                        fontSize: '20px',
                        borderRadius: 0,
                        boxShadow: '0px 2px 2px rgba(0,0,0,0.2)',
                        color: '#fff',
                        backgroundColor: '#222',
                        '&:hover': {
                            backgroundColor: '#e091a2',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                        startIcon={<Newspaper />}>
                        See All News
                    </Button>
                </Box>

            </LayoutContainer>
        </Box>
    );
};

export default WhatsNewSection;
