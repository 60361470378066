// src/pages/community/PageCommunityOffers.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';

const PageCommunityOffers: React.FC = () => {
    return (
        <LayoutContainer>
            <Box sx={{ py: 4 }}>
                <Typography variant="h2" gutterBottom>Exclusive Offers</Typography>
                <Typography variant="body1">
                    Check out these special deals we've arranged just for our community members!
                </Typography>
                {/* Here you could map over an array of offers or render any other relevant content */}
            </Box>
        </LayoutContainer>
    );
};

export default PageCommunityOffers;
