import { Box, Button, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Icon, List, ListItem, ListItemIcon, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import ComputerIcon from '@mui/icons-material/Computer'; // Icon for OBS Overlays
import CheckroomIcon from '@mui/icons-material/Checkroom'; // Icon for Viewer Avatars and Personalisation
import ShieldIcon from '@mui/icons-material/Shield'; // Icon for BloomGuard
import { CreditScore, CurrencyPound, FiberManualRecord, HowToReg, Info, Money, Paid, Payment, PriceCheck, Receipt, Toll, Warning } from '@mui/icons-material';

const PagePartnerArtistProgramPaymentTerms: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Payment Terms for Artists</Typography>
                <Typography variant='h5' sx={{ mb: 2 }}>Bloomies Artist Program</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>We're aware many artists have preferred terms when engaging in commission and freelance work.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>Unless otherwise stated, <span style={{ fontWeight: 'bold' }}>larger work items</span> refers to any work where an artist expects to charge over $50 USD.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>For transparency, please find below details around the payment terms we can accept.</Typography>

                <Box sx={{ backgroundColor: '#77777799', p: 2, mb: 2, mt: 2, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Info sx={{ mr: 1 }} /> Building Trust
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        Eligibility for full or partial upfront payment when commencing work is determined on a case-by-case basis. This is based on the artist's portfolio, experience, and the complexity of the component being created. Artists with a proven track record of delivering high-quality work may be eligible for larger upfront payments.
                    </Typography>
                </Box>

                <Grid container spacing={3} sx={{}}>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 1, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <PriceCheck sx={{ mr: 2 }} /> Full Payment Upfront
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            We pay the artist the full amount for quoted works prior to any work commencing.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, color: '#ddd' }}>
                            This is typically reserved for smaller work items from artists who have a proven track record of delivering high-quality work.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 1, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <Toll sx={{ mr: 2 }} /> Split Payment
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            We pay the artist a portion of the quoted amount upfront, with the remainder paid upon completion of the work. Typically 50% upfront.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, color: '#ddd' }}>
                            For artists with limited history hoping to work on larger work items, this is preferred.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 1, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <CreditScore sx={{ mr: 2 }} /> Payment on Delivery
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            We pay the artist in full on completion and delivery of the work item.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, color: '#ddd' }}>
                            This is preferred if you are working on a smaller work items and have limited history.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 1, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <Receipt sx={{ mr: 2 }} /> Hourly Billing
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            We pay the artist based on the number of hours worked at an agreed hourly rate, with payment made after completion of a work item.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, color: '#ddd' }}>
                            For complex work items or experience artists, this is the preferred payment method. We can provide upfront payment for N hours of work in some cases.
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>Invoicing &amp; Payment Methods</Typography>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    Any payouts are subject to receiving a valid invoice from the artist. Invoices must include the artist's full name, address, and paypal email address. We accept invoices in PDF format only.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 1, color: '#fff', fontWeight: 'bold' , display: 'flex', alignItems: 'center' }}>
                    <CurrencyPound  sx={{ mr: 1 }}/> Acceptable currencies for invoices are USD or GBP.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 1, color: '#fff', fontWeight: 'bold' , display: 'flex', alignItems: 'center' }}>
                    <Payment  sx={{ mr: 1 }}/> Payment via PayPal only.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    PayPal may charge fees for receiving payments, which are the responsibility of the artist.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    Please send all invoices to <a style={{ color: '#ffff55'}} href="mailto:lily@everbloom.media">lily@everbloom.media</a> with subject 'Invoice: ' followed by your name.
                </Typography>
                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 29th April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PagePartnerArtistProgramPaymentTerms;
