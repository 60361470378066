import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { EverbloomPost } from '../types/EverbloomUpdateEntry';
interface EverbloomUpdateEntryProps {
    entry: EverbloomPost;
}

const EverbloomUpdateEntryComponent: React.FC<EverbloomUpdateEntryProps> = ({ entry }) => {
    return (
        <Card sx={{
            display: 'flex', flexDirection: { xs: 'column' }, width: '100%', backgroundColor: '#252525', userSelect: 'none', cursor: 'pointer', '&:hover': {
                opacity: 0.8,
            }
        }}>
            <CardMedia
                component="img"
                sx={{ width: { md: '100%' }, height: '150px', objectFit: 'cover', objectPosition: 'center', position: 'relative' }}
                image={entry.post_imageUrl}
                alt={entry.post_title}
            />
            <CardContent sx={{ flex: '1 0 auto', backgorundColor: '#111' }} >
                <Typography variant="h6" color="#fff" sx={{ margin: 1, mb: 0, textOverflow: 'ellipsis' }}>{entry.post_title}</Typography>
                <Box sx={{margin: 0.5, mb: 0, padding: 1, backgroundColor: '#444', color: '#222', display: 'inline-block', borderRadius: 2, }}>
                    <Typography variant="body2" color='#eee' sx={{fontSize: '14px'}}>{entry.post_category}</Typography>
                    </Box>

                <Typography variant="body2" sx={{ margin: 1 }} color="#ddd">{entry.post_content}</Typography>
                <Typography variant="caption" sx ={{ margin: 1}} color="#bbb" mt={1}>{entry.post_published_date.toLocaleDateString()}</Typography>
            </CardContent>
        </Card>
    );
};

export default EverbloomUpdateEntryComponent;