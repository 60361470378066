export interface EverbloomPost {
    post_id: number; /* unique post id */
    post_published: boolean; /* indicates whether post is published, default no */
    post_published_date: Date; /* when the post was posted, default current time */
    post_slug: string; /* the slug, part of the URL that's visible */
    post_title: string; /* the title of the post */
    post_category: string; /* the category the post belongs to, helps with grouping */
    post_type: EverbloomPostType; /* the kind of post it is */
    post_content: string; /* string data of the post for search and previews */
    post_is_discussion: boolean; /* indicates whether comments are allowed , default no */
    post_is_reactable: boolean; /* indicates whether reactions are allowed, default no */
    post_author_id: number; /* user id of the author */
    post_imageUrl: string; /* image url for the post, used in thumbnails and header section of post */
    post_thumbUrl: string; /* lower resolution version of the image */
    post_is_official: boolean; /* indicates whether the post is made by the bloomies team, default false */
}

export enum EverbloomPostType {
    Latest,
    Event,
    Offer, /* offers, such as third party sponsors */
    Updates, /* patch notes and user friendly updates */
    Creators, /* creators only updates and admins */
    UserStatus, /* users can make posts */
}

export enum EverbloomPostSectionType {
    Paragraph, /* paragraph */
    HeadingBig, /* big heading (h2) */
    Heading, /* normal heading (h3) */
    Image, /* image */
    Button, /* button with link */
}

export enum EverbloomReactionType {
    THUMBS_UP,
    THUMBS_DOWN,
    HEART,
    TRASH,
}

export interface EverbloomReaction {
    post_id: number; /* the post the user reacted to */
    user_id: number; /* the user making the reaction */
    reaction_type: EverbloomReactionType; /* what reaction was it? */
    reaction_date: Date; /* when did the user react?, deafult now */
}

export interface EverbloomPostSection {
    post_id: number; /* the post this section relates to */
    post_section_id: number; /* unique id for the post section */
    post_section_active: boolean; /* if active, this section will be visible */
    post_section_index: number; /* to sort sections for display, smaller indexes shown first, larger indexes shown last */
    post_section_type: EverbloomPostSectionType; /* the kind of section this is */
    post_section_content?: string; /* for most types, this is the text content or visible label */
    post_section_url?: string; /* for most types this is the url for the button or image */
    post_section_alt?: string; /* an extra field, can be used for alt tags for image, or as extra meta information for other types in the future */
}