import React, { useRef, useEffect, useState } from 'react';

export enum BloomRendererEffectType {
    Grayscale = "grayscale",
    Blur = "blur" // Add more effect types here as needed
}

export interface BloomRendererEffectParams {
    type: BloomRendererEffectType;
  }
  
  class BloomRendererEffectGrayscale implements BloomRendererEffectParams {
    type: BloomRendererEffectType;
    intensity: number; // 0 is no effect, 1 is completely grayscale
  
    constructor(intensity: number) {
      this.intensity = intensity;
      this.type = BloomRendererEffectType.Grayscale;
    }
  }
  

// Enumeration for scene element types
export enum BloomRendererSceneElementType {
  Circle = "circle",
  Square = "square",
  Image = "image" // Added image type
}

// Type definition for a single scene element
export interface BloomRendererSceneElement {
    type: BloomRendererSceneElementType;
    x: number;
    y: number;
    xj?: number;
    yj?: number;
    xm?: number;
    ym?: number;
    size: number;
    color?: string;
    imageUrl?: string;
    _width?: number;
    _height?: number;
    _ready?: boolean;
    _ref?: any;
    effects?: BloomRendererEffectParams[]; // Array of effects to apply to this element
  }
  

// Type definition for the scene
export interface BloomRendererScene {
  elements: BloomRendererSceneElement[];
}

// Component props
export interface BloomRendererProps {
  width: number;
  height: number;
  scene: BloomRendererScene;
  zoom: number;
  sx: React.CSSProperties;
}

// Helper function to apply effects
const applyEffects = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, effects: BloomRendererEffectParams[]) => {
    let currentContext = ctx;
  
    effects.forEach(effect => {
      switch (effect.type) {
        case BloomRendererEffectType.Grayscale:
          const grayscaleEffect = effect as BloomRendererEffectGrayscale;
          let imageData = currentContext.getImageData(0, 0, canvas.width, canvas.height);
          let data = imageData.data;
  
          for (let i = 0; i < data.length; i += 4) {
            let avg = data[i] * 0.3 + data[i + 1] * 0.59 + data[i + 2] * 0.11;
            data[i] = avg * grayscaleEffect.intensity + data[i] * (1 - grayscaleEffect.intensity);
            data[i + 1] = avg * grayscaleEffect.intensity + data[i + 1] * (1 - grayscaleEffect.intensity);
            data[i + 2] = avg * grayscaleEffect.intensity + data[i + 2] * (1 - grayscaleEffect.intensity);
          }
  
          currentContext.putImageData(imageData, 0, 0);
          break;
        
        // Additional cases for other effects
      }
    });
  
    return currentContext;
  };

  const drawElementSwitch = (ctx: CanvasRenderingContext2D, element: BloomRendererSceneElement, zoom: number) => {
    const centerX = ctx.canvas.width / 2;
    const centerY = ctx.canvas.height / 2;
    
    switch (element.type) {
      case BloomRendererSceneElementType.Circle:
        ctx.fillStyle = element.color!;
        ctx.beginPath();
        ctx.arc(centerX + element.x * zoom, centerY + element.y * zoom, element.size * zoom / 2, 0, 2 * Math.PI);
        ctx.fill();
        break;
      case BloomRendererSceneElementType.Square:
        ctx.fillStyle = element.color!;
        ctx.fillRect(centerX + (element.x - element.size / 2) * zoom, centerY + (element.y - element.size / 2) * zoom, element.size * zoom, element.size * zoom);
        break;
      case BloomRendererSceneElementType.Image:
        if (!element._ready || element._width == undefined || element._height == undefined) {
            return;
        }
        ctx.drawImage(element._ref, 0, 0);
        // ctx.drawImage(element._ref, -centerX + element.x * zoom - element.size * zoom / 2, -centerY + element.y * zoom - element.size * zoom / 2, element._width * zoom, element._height * zoom);
        break;
    }
  };

const BloomRenderer: React.FC<BloomRendererProps> = ({ width, height, scene, zoom, sx }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const elementCache = useRef<Map<string, HTMLCanvasElement>>(new Map());
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  const [loaded, setLoaded] = useState(false);
  let frameCount = 0;
  
  let mouse_x = 0;
  let mouse_y = 0;


  const updateElementCache = (ctx: CanvasRenderingContext2D, element: BloomRendererSceneElement ) => {
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = element._width!;
    offscreenCanvas.height = element._height!;
    const offscreenCtx = offscreenCanvas.getContext('2d')!;

    offscreenCtx.imageSmoothingEnabled = false; // Disable smoothing for pixel art
    offscreenCtx.imageSmoothingQuality = 'low'; // Ensure low-quality scaling
    drawElementSwitch(offscreenCtx, element, 1); // Draw with no zoom initially for caching

    elementCache.current.set(element.imageUrl!, offscreenCanvas); // Use imageUrl as a unique key
  };

  const loadElement = (ctx: CanvasRenderingContext2D, element: BloomRendererSceneElement) => {
    let centerX = ctx.canvas.width / 2;
    let centerY = ctx.canvas.height / 2;

    if (element._ready && element._ref != null) {
        return;
    }
    
    switch (element.type) {
      case BloomRendererSceneElementType.Image:
        if (element.imageUrl == null)
            {
                console.warn('Error with resource, no image url.');
                return;
            }
          const image = new Image();
          image.onload = () => {
            element._width = image.width;
            element._height = image.height;
            element._ready = true;
            element._ref = image;
            updateElementCache(ctx, element); 
          };
          element._ref = image;
          image.src = element.imageUrl;
          return;
        }
    };
  
  window.addEventListener('mousemove', (ev: MouseEvent) => {
    if (canvasRef.current == null) return;
    const center_x = canvasRef.current.clientWidth / 2;
    const center_y = canvasRef.current.clientHeight / 2;

    mouse_x = Math.max(-1, Math.min(1, (ev.clientX - center_x) / window.innerWidth)) * 2;
    mouse_y = Math.max(-1, Math.min(1, (ev.clientY - center_y) / window.innerHeight)) * 2;
  })
  const frameRate = 60; // Target frame rate
  const frameDuration = 1000 / frameRate;
  const frameLimiter = false;
  const showFps = window.location.search.indexOf('showfps=true') >= 0;

    // Include effect application in the drawElement function
    const drawElement = (ctx: CanvasRenderingContext2D, element: BloomRendererSceneElement) => {
    
        if (element._width == null || element._height == null) {
            tempCanvas.width = tempCanvas.height = element.size;
        } else {
            tempCanvas.width = element._width;
            tempCanvas.height = element._height;
        }

        const cache = elementCache.current.get(element.imageUrl!);
        if (!cache) {
          // If not in cache, draw and update cache (fall-back, should not happen often)
          updateElementCache(ctx, element);
        }

        const cachedCanvas = elementCache.current.get(element.imageUrl!);

        if (!cachedCanvas ) return;

        if (element._width == 0 || element._height == 0) {
            return;
        }

        if (tempCtx == null) return;
        tempCtx.imageSmoothingEnabled = false;
        tempCtx.imageSmoothingQuality = 'low';
        // drawElementSwitch(tempCtx, element, zoom);
        /*
        if (element.effects?.length && tempCtx) {
          ctx = applyEffects(tempCtx, tempCanvas, element.effects);
        }*/

        const baseWidth = element._width ?? 0;
        const baseHeight = element._height ?? 0;

        const jScale = Math.sin(frameCount / 80);

        const scale = zoom * Math.max(ctx.canvas.width / width, ctx.canvas.height / height)

        let x = -(baseWidth / 2) * scale;
        let y = -(baseHeight / 2) * scale;
        let w = baseWidth * scale;
        let h = baseHeight * scale;

        x += element.x * scale;
        y += element.y * scale;

        x += (element.xj ?? 0) * jScale * scale;
        y += (element.yj ?? 0)* jScale * scale;

        x += (element.xm ?? 0) * (scale / 2) * -mouse_x;
        y += (element.ym ?? 0) * (scale / 2) * -mouse_y;

        y -= (element.ym ?? 0) * (window.scrollY / window.innerHeight)  * (scale * 2);

        x += ctx.canvas.width / 2;
        y += ctx.canvas.height / 2;
      
        // Finally, draw the result on the main canvas
        ctx.drawImage(cachedCanvas, x, y, w, h);
      };

      const renderScene = (ctx: CanvasRenderingContext2D, fps: number) => {
        ctx.clearRect(0, 0, width, height); // Clear the canvas
        scene.elements.forEach(element => drawElement(ctx, element)); // Draw each element in the scene
        ctx.beginPath();
        frameCount++;
        if (showFps) {
            ctx.font = "Arial 12px";
            ctx.fillStyle = '#000';
            ctx.fillText(`${fps} fps`, 6, (ctx.canvas.height - 4));
            ctx.fillStyle = '#fff';
            ctx.fillText(`${fps} fps`, 5, (ctx.canvas.height - 5));
        }
      };

      useEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) return;
    
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.imageSmoothingEnabled = false;
        ctx.imageSmoothingQuality = 'low';
        if (loaded == false) {
            scene.elements.forEach(element => loadElement(ctx, element));
            setLoaded(true);
        }
    
        let animationFrameId: number; // To store the requestAnimationFrame ID

        let lastRenderTime = Date.now();

        const frame = () => {
          const currentTime = Date.now();
          const timeSinceLastRender = currentTime - lastRenderTime;
          let shouldRender = frameLimiter ? timeSinceLastRender >= frameDuration && frameLimiter : true;
    
          if (shouldRender) {
            canvas.width = canvas.clientWidth / 2;
            canvas.height = canvas.clientHeight / 2;
            renderScene(ctx, Math.round(1000 / timeSinceLastRender));
            lastRenderTime = currentTime - (timeSinceLastRender % frameDuration);
          }
    
          animationFrameId = requestAnimationFrame(frame);
        };
    
        animationFrameId = requestAnimationFrame(frame); // Start the animation
    
        return () => cancelAnimationFrame(animationFrameId);// Cleanup the animation frame on unmount
      }, [width, height, scene, zoom, loaded]);

  return (
    <canvas ref={canvasRef} width={canvasRef.current?.clientWidth ?? 512} height={canvasRef.current?.clientHeight ?? 512} style={{ ...sx, width: '100%', height: '100%', objectFit: 'cover', imageRendering: 'pixelated' }} />
  );
};

export default BloomRenderer;
