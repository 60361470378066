import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#534bae',
      main: '#ffffff',
      dark: '#1e0e3a',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#a0f0f0',
      main: '#75e8e7',
      dark: '#42bfbf',
      contrastText: '#000000',
    },
    error: {
      light: '#ff6666',
      main: '#ff0000',
      dark: '#b20000',
    },
    success: {
      main: '#5bd561',
    },
    
  },
  typography: {
    fontFamily: '"Rubik", Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 700,
      color: '#ffffff'
    },
    h2: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 700,
      color: '#ffffff'
    },
    h3: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 500,
      fontSize: 42,
      color: '#ffffff'
    },
    h4: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 500,
      fontSize: 28,
      color: '#ffffff'
    },
    h5: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 400,
      fontSize: 20,
      color: '#eeeeee'
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      color: '#ffffff',
    },
    body2: {
      fontSize: 16,
      textDecoration: 'none',
      color: '#ffffff',
    },
    h6: {
      color: '#ffffff',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {

          textAlign: 'center',
          color: '#ffffff', // Ensures text color in table cells is white
          '& .MuiTableCell-root:nth-of-type(0)': {
            textAlign: 'left',
          },
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: '#2e2e2e' // Darker shade for alternate rows
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#232323' // Base color
          },
          '& .MuiTableRow-root': {
            borderBottom: '1px solid #3c3c3c' // Border color between rows
          }
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'heroSub' },
          style: {
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontSize: '24px',
            display: 'block',
            margin: 6,
            letterSpacing: '-0.2px',
            color: '#ffffff', // Optional: set a specific color if needed
          }
        },
        {
          props: { variant: 'heroTitle' },
          style: {
            display: 'block',
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontWeight: 700,
            fontSize: '64px',
            letterSpacing: '-1px',
            color: '#ffffff',
          }
        },
        {
          props: { variant: 'footerLink' },
          style: {
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontSize: 14,
            textDecoration: 'none',
            color: '#dddddd',
            cursor: 'pointer',
            padding: 2,
          }
        },
      ],
    },
  },
});

export default theme;
