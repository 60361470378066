// src/pages/PageProfile.tsx
import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import HeaderSection from '../sections/HeaderSection';
import { ProfileTheme } from '../types/ProfileTheme';
import ProfileSection from '../sections/ProfileSection';
import { ProfileDetails } from '../types/ProfileDetails';

// nightsky theme
const nightsky: ProfileTheme = {
    avatarUrl: '/assets/avatar_example.png',
    backgroundUrl: '/assets/theme/nightsky/background.jpg',
    overlayUrls: [],
    horizontalRuleUrl: '/assets/theme/nightsky/seperator.png',
    backgroundColor: '#181b1f',
    overlayStyles: [],
    fontStyles: {
        title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
        subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
        text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
        para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
    },
    tabStyle: {
        backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
    },
    containerStyle: { backgroundColor: '#181b1f' },
    globalStyles: {},
};

const purplevista: ProfileTheme = {
    avatarUrl: '/assets/avatar_example.png',
    backgroundUrl: '/assets/theme/purplevista/background.jpg',
    overlayUrls: ['/assets/theme/purplevista/overlay1.jpg', '/assets/theme/purplevista/overlay2.jpg'],
    horizontalRuleUrl: '/assets/theme/purplevista/seperator.jpg',
    backgroundColor: '#1b1c1e',
    overlayStyles: [{
        mixBlendMode: 'screen',
        animation: 'parallax 92s linear infinite',
        opacity: 0.4,
    },
    {
        mixBlendMode: 'screen',
        animation: 'parallax 185s linear infinite',
        opacity: 0.4,
    }],
    fontStyles: {
        title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
        subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
        text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
        para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
    },
    tabStyle: {
        backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
    },
    containerStyle: { backgroundColor: '#1b1c1e' },
    globalStyles: {
        filter: 'grayscale(50%)',
    },
};

const userTheme = purplevista;


const userDetails: ProfileDetails = {
    username: "PetalEverbloom",
    joinDate: 1687302000000,
    lastSeen: 1713567256305,
    xp: 382,
    achievements: [{
        imageUrl: '/assets/badges/award_chatterbox.png',
        title: 'Chatterbox',
        description: 'Awarded for sending over 1,000 chat messages on Twitch or Discord',
        earnedAt: 1703567256305
    }, {
        imageUrl: '/assets/badges/award_developer.png',
        title: 'Developer',
        description: 'Awarded for code contributions in one or more official projects.',
        earnedAt: 1706567256305
    }],
    gender: '',
    pronouns: 'she/her',
    aboutMe: '',
    interests: '',
    languages: ['English', 'Japanese'],
}

const PageProfile: React.FC = () => {
    return (
        <div>
            <ProfileSection theme={userTheme} details={userDetails} username={userDetails.username} />
            {/* More sections or components can be added here */}
        </div>
    );
};
export default PageProfile;
