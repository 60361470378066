import React, { useState } from 'react';
import { Box, Button, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Icon, List, ListItem, ListItemIcon, CircularProgress, TextField } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { useAuth } from '../context/AuthContext';
import { HeartBroken } from '@mui/icons-material';

const PageSettingsOverview: React.FC = () => {
    const { user } = useAuth();
    const [values, setValues]= useState({ displayName: user?.username })
    const [loading, setLoading] = useState(false);
    const [textValue, setTextValue] = useState('');

    const handleSaveChanges = () => {
        // Simulate API call for saving changes
        setLoading(true);
        setTimeout(() => {
            // Simulated success
            setLoading(false);
            alert('Changes saved successfully');
        }, 2000);
    };

    return (
        <Box component="section" sx={{ position: 'relative', backgroundColor: '#212022', p: 3, borderRadius: '12px', minHeight: '400px' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Overview</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>The platform is currently under development and some preferences may not work as intended or be unavailable at this time. </Typography>
                {

                    /*
    <Paper sx={{ p: 2, mb: 2, backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                    <TextField
                        label="Display Name"
                        variant="outlined"
                        sx={{ color: '#fff', border: 'solid 1px #444' }}
                        fullWidth
                        value={values.displayName}
                        onChange={(e) => setTextValue(e.target.value)}
                        disabled={loading}
                    />
                </Paper>
                <Button
                         variant="contained"
                         onClick={handleSaveChanges}
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1,
                        pb: 1,
                        bgcolor: '#af5166',
                        color: 'primary.contrastText',
                        '&:hover': {
                            bgcolor: '#d9738a',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>

                    */
                }
            
            <Typography variant='body2' sx={{ pt: 1, pb: 1, color: '#666', lineHeight: ' 32px' }}>Actually... all preferences are unavailable! </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PageSettingsOverview;
