// src/sections/ProfileSection.tsx
import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ProfileTheme } from '../types/ProfileTheme';
import { ProfileDetails } from '../types/ProfileDetails';
import LayoutContainer from '../components/LayoutContainer';
import { calculateLevel, timeAgo } from '../utils/ProfileUtils';
import { relative } from 'path';
import AchievementBadge from '../components/AchievementBadge';
import ThemeBackground from '../components/ThemeBackground';
import ProfileTabs from '../components/ProfileTabs';

interface ProfileSectionProps {
    theme: ProfileTheme;
    details: ProfileDetails;
    username: string;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ theme, details, username }) => {
    return (
        <Box component="section" sx={{ background: theme.backgroundColor, color: theme.fontStyles.text.color, position: 'relative', minHeight: '100vh' }}>
            <ThemeBackground theme={theme} />
            <Box sx={{ paddingTop: '64px', position: 'relative' }}>
                <LayoutContainer>
                    <Grid container direction="row" spacing={4} width={"auto"}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                            <Box sx={{
                                marginTop: '80px',
                                height: '380px',
                                padding: '10px',
                                background: 'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.19))',
                                position: 'relative',
                                borderRadius: '15px',
                                userSelect: 'none',
                            }}>
                                <Box sx={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    backdropFilter: 'blur(8px);',
                                    opacity: 0.5,
                                }}>

                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <Box sx={{
                                        margin: 'auto',
                                        height: '310px',
                                        position: 'relative',
                                        textAlign: 'center',
                                    }}>
                                        <img src={theme.avatarUrl} alt={`${details.username}'s avatar`} style={{ zoom: 3, imageRendering: 'pixelated' }} />
                                    </Box>
                                    <Typography sx={{ ...theme.fontStyles.subheading, textShadow: '0px 4px 0px rgba(0,0,0,0.5)' }} align={"center"}>Level {calculateLevel(details.xp)}</Typography>



                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                marginTop: '80px',
                                padding: '40px',
                                height: '320px',
                                position: 'relative',
                                background: 'linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.19))',
                                borderRadius: '24px',
                                userSelect: 'none',
                            }}>
                                <Box sx={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    backdropFilter: 'blur(8px);',
                                    opacity: 0.5,
                                }}>
                                </Box>
                                <Box sx={{
                                    position: 'relative',
                                }}>
                                    <Typography sx={{ ...theme.fontStyles.title, textShadow: '0px 4px 0px rgba(0,0,0,0.5)' }} align={"left"}>{details.username}</Typography>
                                    <Typography sx={{ ...theme.fontStyles.text, textShadow: '0px 4px 0px rgba(0,0,0,0.5)' }} align={"left"}>{details.pronouns}</Typography>
                                    <Typography sx={{ ...theme.fontStyles.subheading, textShadow: '0px 4px 0px rgba(0,0,0,0.5)' }} align={"left"}>Joined {timeAgo(details.joinDate)}</Typography>
                                    <Box height={20} />
                                    <Typography sx={{ ...theme.fontStyles.text, textShadow: '0px 4px 0px rgba(0,0,0,0.5)' }} align={"left"}>Achievements</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 2, paddingTop: 1 }}>
                                        {details.achievements.map((achievement, index) => (
                                            <AchievementBadge key={index} achievement={achievement} size={100} />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </LayoutContainer>
            </Box>
            <Box sx={{
                position: 'relative',
                marginTop: '60px',
            }}>
                <LayoutContainer>
                    {/* We will create tabs, each tab is styled by the themes theme.tabStyle. */}
                    {/* Each tab also has an icon, there are 5 tabs, 'Bio', 'Wardrobe', 'Achievements', 'Inventory', 'Friends' */}
                    {/* If the current profile is for the logged in user, they will see all 5, otherwise they will see, 'Bio' and 'Achivements'. */}
                    {/* Each tab will render a subpage, like PageProfileBio and route to /profile/<username>/bio or /profile/<username>/wardrobe */}
                    <Box sx={{ 
                        ...theme.containerStyle,
                        backgroundColor: theme.backgroundColor,
                        padding: 2,
                        borderRadius: 4,
                        minHeight: '300px',
                    }}>
                        <ProfileTabs isLoggedInUser={true} rootUrl={`/profile/${username?.toLowerCase()}`} theme={theme} />
                    </Box>
                </LayoutContainer>

            </Box>
        </Box>
    );
};

export default ProfileSection;
