import React from 'react';
import { Box, Button, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import LayoutContainer from '../components/LayoutContainer';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ChatIcon from '@mui/icons-material/Chat';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LinkIcon from '@mui/icons-material/Link';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Info, Redeem } from '@mui/icons-material';


const PageSubscriptionTiers: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Subscription Tiers</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>Creator tools are available for all creators free of charge.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>Our advanced features are more resource intensive and designed to further empower creators, they require a monthly subscription to maintain access and support the platform.</Typography>

                <Box sx={{ backgroundColor: '#9a68b299', p: 2, mb: 2, mt: 1, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Redeem sx={{ mr: 1 }} /> Note for Beta Users 
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1}}>
                        Access to the Amethyst tier is free of charge as a thank you for helping grow and test the platform.
                    </Typography>
                </Box>
                <TableContainer component={Paper} sx={{ my: 2, backgroundColor: '#232323', color: '#fff' }}>
                    <Table>
                        <TableHead sx={{
                            '& .MuiTableCell-root': {
                                color: '#fff', // Ensure text in headers is white
                                fontWeight: 'bold',
                            },
                            '& .MuiTableCell-root:nth-of-type(2)': {
                                backgroundColor: '#66666699' // Grey color for Quartz Blossom
                            },
                            '& .MuiTableCell-root:nth-of-type(3)': {
                                backgroundColor: '#9a68b299' // Light purple for Amethyst Bloom
                            },
                            '& .MuiTableCell-root:nth-of-type(4)': {
                                backgroundColor: '#195f9999' // Deep pink for Orchid Gem
                            }
                        }}>
                            <TableRow>
                                <TableCell>Features / Tiers</TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1">Quartz</Typography>
                                    <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Free</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1">Amethyst</Typography>
                                    <Typography variant="body2" sx={{ fontSize: '0.8rem', textDecoration: 'line-through' }}>£5/mo</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1">Sapphire</Typography>
                                    <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>£15/mo</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Basic Creator Tools */}
                            <TableRow>
                                <TableCell>Basic Creator Tools</TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Stream Overlays & Widgets */}
                            <TableRow>
                                <TableCell>Stream Overlays & Widgets</TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Basic Insights & Moderation */}
                            <TableRow>
                                <TableCell>Basic Insights & Moderation</TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* BloomGuard Safety Feature */}
                            <TableRow>
                                <TableCell>BloomGuard Safety Feature</TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Cross-Platform Alerts */}
                            <TableRow>
                                <TableCell>Cross-Platform Alerts &amp; Chat</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* AI-Driven Moderation */}
                            <TableRow>
                                <TableCell>AI-Driven Moderation</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Chat History Analysis */}
                            <TableRow>
                                <TableCell>Chat History Analysis</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Custom Avatar Shop Items */}
                            <TableRow>
                                <TableCell>Custom Avatar Shop Items</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Link to Other Platforms */}
                            <TableRow>
                                <TableCell>Link to Other Platforms</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Receive Donations */}
                            <TableRow>
                                <TableCell>Receive Donations</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="warning" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Advanced Broadcast Insights */}
                            <TableRow>
                                <TableCell>Advanced Broadcast Insights</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* Clip Suggestions Feature */}
                            <TableRow>
                                <TableCell>Clip Suggestions Feature</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>
                            {/* StreamLuxe Quality Analysis */}
                            <TableRow>
                                <TableCell>StreamLuxe Quality Analysis</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Custom Bot</TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CancelIcon color="action" /></TableCell>
                                <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Max Supported Platforms</TableCell>
                                <TableCell>1</TableCell>
                                <TableCell>2</TableCell>
                                <TableCell>Unlimited</TableCell>
                            </TableRow>
                            {/* Doncation Cut */}
                            <TableRow>
                                <TableCell>Platform Donation Cut</TableCell>
                                <TableCell>N/A</TableCell>
                                <TableCell>30%</TableCell>
                                <TableCell>20%</TableCell>
                            </TableRow>
                            {/* Support Level */}
                            <TableRow>
                                <TableCell>Support Level</TableCell>
                                <TableCell>Website Help</TableCell>
                                <TableCell>Email Support</TableCell>
                                <TableCell>Discord (24hr Response)</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='h4' sx={{ mb: 2 }}>Detailed Features Description</Typography>
                <Typography variant='body2' sx={{ mb: 1 }}>Here's a deeper look at what each subscription tier offers to our creators:</Typography>


                <Grid container spacing={4} sx={{}}>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <DesktopWindowsIcon sx={{ mr: 2 }} /> Basic Creator Tools
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Access to customizable stream overlays, widgets for showcasing user avatars such as chat and alerts.
                        </Typography>
                    </Grid>
            
                    <Grid item md={6} xs={12}>

                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <SecurityIcon sx={{ mr: 2 }} /> BloomGuard Safety Feature
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Advanced real-time monitoring and moderation tools to maintain community standards and safety. Opt-in for additional protection, automatically intervening with malicious users.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>

                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <NotificationsActiveIcon sx={{ mr: 2 }} /> Cross-Platform Alerts
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Aggregate alerts and interactions from multiple platforms for streamlined community management. Combine your YouTube, Kick and Twitch alerts & chat on the same stream.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <ChatIcon sx={{ mr: 2 }} /> AI-Driven Moderation and Chat History Analysis
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Enhanced moderation capabilities that include AI-driven systems to analyze chat behaviors and histories.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>

                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <StorefrontIcon sx={{ mr: 2 }} /> Custom Avatar Shop Items
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Ability for creators to add custom items to the avatar shop, enhancing viewer interaction. Let your followers show off your own cosmetics across all streams using the bloomies platform.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <LinkIcon sx={{ mr: 2 }} /> Link to Other Platforms
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Enables creators to connect their Bloomies profile with other social media platforms, expanding their reach.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <MonetizationOnIcon sx={{ mr: 2 }} /> Receive Donations
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Provides an avenue for creators to receive donations directly, with reduced platform fees at higher tiers. We take a small fee to support transaction costs and support the platform's ongoing costs.
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>


                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <InsightsIcon sx={{ mr: 2 }} /> Advanced Broadcast Insights
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Deep analytics that analyze viewership and engagement metrics to help creators optimize their content.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>

                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <VideoLibraryIcon sx={{ mr: 2 }} /> Clip Suggestions Feature
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Identifies high-engagement segments for potential clips, aiding content creation and distribution. 
                        </Typography>

                    </Grid>
                    <Grid item md={6} xs={12}>

                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <VideoLibraryIcon sx={{ mr: 2 }} /> StreamLuxe Quality Analysis
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Available only at the Orchid Gem level, this feature periodically reviews stream quality, offering suggestions to enhance audio and visual elements. Never worry again about being too loud or too quiet.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <SupportAgentIcon sx={{ mr: 2 }} /> Support Level
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Tiered support options from website help, email support, to dedicated Discord support with fast response times for premium users.
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{ backgroundColor: '#77777799', p: 2, mb: 2, mt: 3, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Info sx={{ mr: 1 }} /> Under Development
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1}}>
                        These tiers are unavailable until the platform launches, but are presented in the interests of transparency. The subscription model is designed to support the platform and it's ongoing costs.
                    </Typography>
                </Box>

                <Typography variant='body2' sx={{ mt: 2 }}>
                    Many of these features are curently under development and will be available for testing as they become available for creators whilst the platform is in beta.
                </Typography>

                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 24th April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PageSubscriptionTiers;
