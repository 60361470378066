import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import WardrobeIcon from '@mui/icons-material/Style';
import StarIcon from '@mui/icons-material/StarBorder';
import InventoryIcon from '@mui/icons-material/Inventory';
import FriendsIcon from '@mui/icons-material/People';
import { ProfileTheme } from '../types/ProfileTheme';

export interface ProfileTabsProps {
    isLoggedInUser: boolean;
    theme: ProfileTheme;
    rootUrl: string;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ isLoggedInUser, theme, rootUrl }) => {
    const tabs = [
        { label: 'Bio', path: `${rootUrl}/bio`, icon: <PersonIcon /> },
        { label: 'Wardrobe', path: `${rootUrl}/wardrobe`, auth: true, icon: <WardrobeIcon /> },
        { label: 'Achievements', path: `${rootUrl}/achievements`, icon: <StarIcon /> },
        { label: 'Inventory', path: `${rootUrl}/inventory`, auth: true, icon: <InventoryIcon /> },
        { label: 'Friends', path: `${rootUrl}/friends`, auth: true, icon: <FriendsIcon /> }
    ].filter(tab => !tab.auth || isLoggedInUser);

    const currentPath = window.location.pathname;

    return (
        <Tabs value={currentPath} sx={{ ...theme.tabStyle, backgroundColor: 'transparent' }}>
            {tabs.map((tab, index) => (
                <Tab 
                    key={index}
                    label={
                        <Link to={tab.path} style={{ ...theme.tabStyle, textDecoration: 'none', display: 'flex', alignItems: 'center', marginRight: 8 }}>
                            <Box component="span" sx={{ mr: 1 }}>{tab.icon}</Box>
                            {tab.label}
                        </Link>
                    }
                    value={tab.path}
                    sx={{ padding: 0 }} // You might need to adjust the styling to match your theme
                />
            ))}
        </Tabs>
    );
};

export default ProfileTabs;
