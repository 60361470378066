import { Box, Button, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Icon, List, ListItem, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import ComputerIcon from '@mui/icons-material/Computer'; // Icon for OBS Overlays
import CheckroomIcon from '@mui/icons-material/Checkroom'; // Icon for Viewer Avatars and Personalisation
import ShieldIcon from '@mui/icons-material/Shield'; // Icon for BloomGuard
import { FiberManualRecord, HowToReg, Info, Money, Paid, Warning } from '@mui/icons-material';

const PagePartnerBecomeAnArtist: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Bloomies Artist Program</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>For the platform to be a success and to provide enough options for self-expression, we need many talented artists to build out our initial wardrobe and shop items.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>We are looking for talented artists on an ongoing basis to produce consistent art within our artistic framework. This is fully paid work with expectation for commercial licence, if you're interested please look below for more details.</Typography>
                <Typography variant='body2' sx={{ color: '#ddd', pt: 1, pb: 1 }}>Be aware that the platform is currently under active development.</Typography>

                <Box sx={{ backgroundColor: '#77777799', p: 2, mb: 2, mt: 3, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Info sx={{ mr: 1 }} /> Funding and Budget
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        The total allocated budget for Phase 1 of the artist program is $2,000 USD. This will be split between artists based on the amount of work they produce. The total budget for the entire program is $10,000 USD.
                    </Typography>
                </Box>

                <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>How it works</Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    As an artist you will be tasked with creating <span>components</span> which are used to build up the wardrobe and shop items. These components consist of multiple layers of artwork, and include of items of clothing, hair styles, accessories and more.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    You will be provided with a brief for each component, which includes references and will be expected to fully rendered layers as per our artistic guidelines.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Each component may consist of multiple layers, and multiple variations to account for different poses and body types. You will be expected to provide each layer as a separate file. The final component will be assembled by our team.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Each artist on the program can expect to receive between $15 and $150 per component, depending on the complexity and terms agreed between yourself and the company beforehand.
                </Typography>
                <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>Eligibility Guidelines</Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    To be considered for our artist program, applicants must meet the following eligibility criteria:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Demonstrable experience in producing digital art for video games or related technical fields. Applicants should provide a portfolio showcasing their work on previous projects to illustrate their expertise and alignment with our artistic requirements.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Capability to consistently adhere to both technical and artistic specifications as outlined in our project documentation. Applicants must exhibit a strong understanding of style guides, resolution settings, and file format requirements.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Commitment to provide a full commercial license for all produced works. Artists must agree not to repost, share, or redistribute any assets created for our platform without explicit permission.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Professionalism in work engagements, including the submission of invoices and the ability to receive payments via PayPal.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Efficient communication skills, with the ability to actively participate in discussions via Discord and manage contractual and financial correspondence through email. It is imperative that all participants maintain prompt and clear communication to facilitate seamless collaboration.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>
                            Applicants must be of legal age to enter into binding contracts and non-disclosure agreements as defined by their respective jurisdiction. By applying, you confirm that you meet the age requirement necessary to legally engage in and uphold contractual and confidentiality obligations.
                        </Typography>
                    </ListItem>
                </List>


                <Typography variant='body2' sx={{ mt: 1, color: '#fcc' }}>
                    Note: You must be at least 18 years of age to participate in this program.
                </Typography>

                <Typography variant='body2' sx={{ mt: 1, }}>
                    If you don't meet all of the above guidelines, feel free to apply anyway. We just want to make sure all artists we work with are able to produce high quality work.
                </Typography>
                <Button href="https://discord.gg/bloomies"
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1.5,
                        pb: 1,
                        bgcolor: '#af5166',
                        color: 'primary.contrastText',
                        '&:hover': {
                            bgcolor: '#d9738a',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                    startIcon={<HowToReg />}
                >
                    Apply Now
                </Button>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    When joining the discord server, indicate that you are an artist during the onboarding process.
                </Typography>
                <Box sx={{ backgroundColor: '#ff777799', p: 2, mb: 2, mt: 3, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Warning sx={{ mr: 1 }} /> Discord Rules
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        The server is a community server and self-promotion is not permitted, do not post your portfolio in the general introductions channel or any other channel except the <a href="https://discord.com/channels/1066513215359549491/1234790892083347479" style={{ color: '#ff8'}}>work-introductions channel</a>. You may be removed from the server by our moderation team if you message other users to promote your services.
                    </Typography>
                </Box>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    Please post your full portfolio in the <a href="https://discord.com/channels/1066513215359549491/1234790892083347479" style={{ color: '#ff8'}}>work-introductions channel</a> under the 'Platform Team' category.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, color: '#fff' }}>
                    Our wider team will review your portfolio and get back to you as soon as possible, and reach out directly if they have any questions.
                </Typography>
                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 29th April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PagePartnerBecomeAnArtist;
