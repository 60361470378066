import React from 'react';
import { Box, Button, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Grid, List, ListItem, ListItemIcon } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import LayoutContainer from '../components/LayoutContainer';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ChatIcon from '@mui/icons-material/Chat';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LinkIcon from '@mui/icons-material/Link';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ArtTrack, ColorLens, Download, Email, FiberManualRecord, Info, Redeem, Upload, Warning } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';


const PageLookingForArtists: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Artistic Guidelines</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>We are seeking talented artists on an ongoing basis to provide assets for our avatar customisation system.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>This platform will offer users a way to assemble an avatar which will be utilized on broadcasts and systems which use the platform. As users engage with their creators, they can unlock additional customisation options. Building out a library of quality assets that provide enough room for creativity and self-expression is greatly important.</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>This document serves to set some standards to maintain a consistent visual aeshtetic and ensure all customizations result in avatars free from visual abnormalities.</Typography>

                <Box sx={{ backgroundColor: '#9a68b299', p: 2, mb: 2, mt: 1, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Redeem sx={{ mr: 1 }} /> Paid Commission
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        Official artists will be paid for their contributions to the platform. Artists will be paid per asset, and will be required to sign a contract to ensure the exclusive rights to the asset are transferred to the platform.
                    </Typography>
                </Box>

                <Box sx={{ backgroundColor: '#6dd17999', p: 2, mb: 2, mt: 1, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <ArtTrack sx={{ mr: 1 }} /> Art Style
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        Pixel art is shown for simplicity in the example images, but we are still establishing a consistent art style for the team. The final output is 192 x 544, but you should work on higher resoultion canvases. (eg. 576 x 1632)
                    </Typography>
                </Box>


                <Box>
                    <Typography variant='h3' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        Technical Guide
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                The rendering process for an avatar consists of multiple layers which are stacked on top of each other to create the final image. Each layer is a separate image file, some of which are hue-shifted to match the user's selected colour scheme.
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Layers are images with the following properties:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Layers are always transparent images.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Final layers are always 192 x 544 pixels in resolution.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Layers must only contain details relevant to their component type.</Typography>
                                </ListItem>
                            </List>

                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Ideally you should draw on a higher resolution canvas of the same aspect ratio and submit higher resolution outwork, it will be scaled down to 192 x 544. For example, you may wish to draw on 576 x 1632 canvas for a 3x scale, we will then scale this down prior to including on the platform but keep the original detailed copies.
                            </Typography>

                            <Typography variant='h5' sx={{ mt: 1, display: 'flex', alignItems: 'center', color: '#fff' }}>
                                Body Types
                            </Typography>

                            <Typography variant='body2' sx={{ mt: 1 }}>
                                The base layer consists of the body shape (excluding head) and is the foundation of the avatar, this includes the pose and any primary sex characteristics.
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Given that the pose and shape of the body may change when changing this base layer, every clothing component must be designed with a base layer in mind.
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Every component may have multiple variations, which consist of additional layers to support different base types.
                            </Typography>

                            <Typography variant='body2' sx={{ mt: 1 }}>
                                To reduce complexity and avoid creating many variations per component, we intend to provide 4 initial base types. Two poses for either feminine or masculine physiques.
                            </Typography>

                            <Typography variant='body2' sx={{ mt: 1, color: '#aaa' }}>
                                When accounting for potential varying base types and the need for multiple layers, a single component might require 4-8 layers to support all variations.
                            </Typography>

                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'center' }}>
                            <img src='/assets/graphics/reference_proportions.png' alt='Example image demonstrating avatar proportions' />
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={5} sx={{ textAlign: 'center' }}>
                            <img src='/assets/graphics/guidelines_complete_layers.png' style={{ marginTop: '40px' }} alt='Example image demonstrating that layers should be completed' />
                            <img src='/assets/graphics/guidelines_components_relevant.png' style={{ marginTop: '10px' }} alt='Example image demonstrating that layers should be completed' />
                            <img src='/assets/graphics/guidelines_no_colour_baking.png' style={{ marginTop: '10px' }} alt='Example image demonstrating how components designed to colour shift should be' />
                            <img src='/assets/graphics/guidelines_no_strokes.png' style={{ marginTop: '10px' }} alt='Example image demonstrating how components should avoid strokes and outlines' />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                                Rules for Consistency
                            </Typography>

                            <Typography variant='body2' sx={{ mt: 1 }}>
                                To maintain consistency between a potentially vast array of user customizations, the following rules help avoid visual abnormalities.
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Facial components such as head, expressions, hair must always be head-on perspective and centered within the frame at head level. This is between head (36px) and chin (100px) vertically.</Typography>

                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Accessories designed to augment the face must consider that the eyeline is always at 74px vertically.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Clothing may be semi-transparent to allow for parts of the base (body) to be visible, but should use the alt layer to render a solid colour behind the body.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Always draw and render the each layer in it's entirety to a consistent quality, even parts that may be obscured. Hair rendered behind the body for example should still be fully drawn.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Use a consistent hue and vary saturation and lightness for components which are intended to have colours customised by the user.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Highlights and stencils should avoid any saturation to avoid abnormalities. For example, sheens on hair should use pure white, and outlines (if absolutely necessary) should use black or gray.</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <Typography variant='body2'>Avoid strokes/hard lines unless necessary. Instead use shadows and highlights to create depth.</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Box>

                <Typography variant='h3' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                    Component Guide
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={7} sx={{ textAlign: 'center' }}>
                            <img src='/assets/graphics/art_example.png' alt='Example image demonstrating image components' />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Many components have multiple layers which render at different stages during the final stacked composition.
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                Some components will be hue-shifted to match the user's selected colour scheme.
                            </Typography>

                            <Typography variant='body2' sx={{ mt: 1 }}>
                                For example, hair comprises two layers, the main layer renders above the head and face. The secondary layer renders behind the head and body, both layers can be shifted in hue.
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1, color: '#aaa' }}>
                                The level of detail in the example is simplified for demonstration purposes.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        The table below serves as a reference for all component types in the system and their requirements.
                    </Typography>
                    <TableContainer component={Paper} sx={{ my: 2, backgroundColor: '#232323', color: '#fff' }}>
                        <Table>
                            <TableHead sx={{
                                '& .MuiTableCell-root': {
                                    color: '#fff', // Ensure text in headers is white
                                    fontWeight: 'bold',
                                },
                                '& .MuiTableCell-root:nth-of-type(2n)': {
                                    backgroundColor: '#66666699' // Grey color for Quartz Blossom
                                },
                            }}>
                                <TableRow>
                                    <TableCell>Component / Specs</TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle1">Variations</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>(if applicable)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle1">Color Shift</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>(user adjustment)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle1">Required</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>(must user select at least one)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle1">Total Layers</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Basic Creator Tools */}
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Theme</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Background image</Typography>
                                    </TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /><Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Context dependent</Typography></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Background</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Foreground</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Base</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Body &amp; Pose</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" />  <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>4 Types</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Skin Palette</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">1</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Body</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Head</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}></Typography>
                                    </TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Skin Palette</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">1</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Head</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Hair</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}></Typography>
                                    </TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Hair Palette</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Back</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Front</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Face</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}></Typography>
                                    </TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Eye Palette</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">3</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Eyes</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Mouth</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Eyebrows/Stencil</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Top</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Upper body garment</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>One per base type</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Optional</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">1</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Top</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Legs</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Lower body garment</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>One per base type</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Optional</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Back</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Front</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Shoes</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Footwear</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>One per base type</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Optional</Typography></TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Socks</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Shoes</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Fullbody</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Additional Garment</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>(eg. dress, coat)</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>One per base type</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Optional</Typography></TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Behind Body</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Overlay</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">Accessory</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>(eg. scarf, hairband, glasses)</Typography>
                                    </TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="success" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>One per base type</Typography></TableCell>
                                    <TableCell><CheckCircleOutlineIcon color="warning" /> <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Optional</Typography></TableCell>
                                    <TableCell><CancelIcon color="action" /></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1">2</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Behind Body</Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>Overlay</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant='h4' sx={{ mt: 4, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        Defining Components &amp; Organisation
                    </Typography>

                    <Typography variant='body2' sx={{ mt: 1 }}>
                        When submitting components, create a directory (folder) for your component which includes a folder per variant, even if you only have one variant. Each variant folder should contain all layers for that variant. Any layers which are not 192 x 544px and in PNG format will be ignored by the system but should still be included.
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        Ideally you should work on a higher resolution canvas and submit the higher resolution artwork, we will scale this down to 192 x 544px for you. For example, you may wish to draw on a 576 x 1632 canvas for a 3x scale, we will then scale this down prior to including on the platform but keep the original detailed copies.
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        You should create a text file in JSON format in the root of the directory called 'component.json' which will contain all the information necessary to describe our component. We will eventually release tools to help create this file.
                    </Typography>

                    <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        Categories
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant='h6' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                                Style
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                The overall style of the component. Valid options include: classic, casual, formal, gothic, streetwear, sporty, fantasy.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='h6' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                                Season
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                The intended season of the component. Valid options include: summer, spring, fall, winter, halloween, christmas, valentines, easter, pride.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='h6' sx={{ mt: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                                Pattern
                            </Typography>
                            <Typography variant='body2' sx={{ mt: 1 }}>
                                The pattern/aesthetic of the component. Valid options include: solid, striped, plaid, polka_dots, floral, camo, tie_dye, tartan, checkered, other_printed.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Typography variant='body2' sx={{ mt: 2 }}>
                    For an editable example of the appropraite file and folder structure, you can download the example component below.
                </Typography>

                <Button href="/assets/downloads/hair_32.zip" download="bloomies_component_example.zip"
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1.5,
                        pb: 1,
                        bgcolor: '#af5166',
                        color: 'primary.contrastText',
                        '&:hover': {
                            bgcolor: '#d9738a',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                    startIcon={<Download />}
                >
                    Download Example Component
                </Button>

                
                <Typography variant='h3' sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                    Additional Resources
                </Typography>

                <Button href="/assets/graphics/rendering_order_help.jpg" download="bloomies_rendering_order.jpg"
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1,
                        pb: 1,
                        bgcolor: '#af5166',
                        color: 'primary.contrastText',
                        '&:hover': {
                            bgcolor: '#d9738a',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                    startIcon={<Download />}
                >
                    Rendering Order Cheat Sheet
                </Button>

                <Typography variant='h3' sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                    Asset Submission Terms
                </Typography>

                <Typography variant='body2' sx={{ mt: 2 }}>
                    When you submit assets for use within our avatar customization system, it is important to understand and agree to the following terms regarding intellectual property and usage rights:
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                    Rights and Permissions
                </Typography>
                <Typography variant='body2' sx={{ mt: 2 }}>
                    By submitting any artwork, images, or other assets ("Assets"), you affirm that you hold all necessary rights to grant us a non-exclusive, royalty-free license to use, display, and distribute these Assets within our platform. This license allows us to integrate your Assets into our system and make them available to our users for avatar customization.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                Asset Ownership for Commissioned Work
                </Typography>
                <Typography variant='body2' sx={{ mt: 2 }}>
                If you are an artist commissioned by Everbloom Media, you agree that upon completion and full payment for your commissioned work, all rights, titles, and interests in the artwork or assets will be exclusively owned by Everbloom Media and its platform. As the creator, you must not post, redistribute, or replicate the commissioned artwork, either in part or in its entirety.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                Usage Restrictions
                </Typography>
                <Typography variant='body2' sx={{ mt: 2 }}>
                By submitting any artwork, images, or other assets ("Assets"), you affirm that you hold all necessary rights to grant us a non-exclusive, royalty-free license to use, display, and distribute these Assets within our platform. This license allows us to integrate your Assets into our system and make them available to our users for avatar customization.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', color: '#fff' }}>
                Reference and Promotion
                </Typography>
                <Typography variant='body2' sx={{ mt: 2 }}>
                If you wish to refer to your work created for the platform, such references must be made within the terms agreed upon prior to the approval of the work by Everbloom Media. Please ensure that any discussion or display of the commissioned work adheres to the agreed terms.
                </Typography>
                <Typography variant='body2' sx={{ mt: 3 }}>
                By submitting your Assets to our platform, you acknowledge and agree to these terms. This agreement ensures the integrity and usability of our avatar customization system and protects both your rights as an artist and our rights as a platform provider.
                    </Typography>

                    <Button href="mailto:lily@everbloom.media?subject=Official Artist Enquiry&body=I have read the artist guidelines and I'm interested in becoming an official artist for the bloomies platform. Here is my price sheet and hourly rate:"
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1.5,
                        pb: 1,
                        bgcolor: '#425b81',
                        color: 'primary.contrastText',
                        '&:hover': {
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                    startIcon={<Email />}
                >
                    Become an Official Artist
                </Button>

                <Button sx={{ backgroundColor: '#5865F2',  p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1.5,
                        ml: 2,
                        pb: 1, }} href="/auth/discord">
                <FontAwesomeIcon icon={faDiscord} style={{ marginRight: '0.5em' }} />
                Join our Discord
            </Button>

            <Box sx={{ backgroundColor: '#ff777799', p: 2, mb: 2, mt: 3, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Warning sx={{ mr: 1 }} /> Discord Rules
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1 }}>
                        The server is a community server and self-promotion is not permitted, do not post your portfolio in the general introductions channel or any other channel except the <a href="https://discord.com/channels/1066513215359549491/1234790892083347479" style={{ color: '#ff8'}}>work-introductions channel</a>. You may be removed from the server by our moderation team if you message other users to promote your services.
                    </Typography>
                </Box>
    

                <Typography variant='body2' sx={{ mt: 3, color: '#aaa' }}>
                    Please send an email to lily@everbloom.media or join our discord and include your price sheet and examples of previous work. We will review your submission and get back to you as soon as possible.
                </Typography>

                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 29th April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PageLookingForArtists;
