import { Box, Button, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Icon, List, ListItem, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import ComputerIcon from '@mui/icons-material/Computer'; // Icon for OBS Overlays
import CheckroomIcon from '@mui/icons-material/Checkroom'; // Icon for Viewer Avatars and Personalisation
import ShieldIcon from '@mui/icons-material/Shield'; // Icon for BloomGuard
import { FiberManualRecord, HowToReg, Info, Money, Paid } from '@mui/icons-material';

const PagePartnerBecomeACreator: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Bloomies Creator Program</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>The creator program aims to serve content creators wishing to integrate with the Bloomies platform.</Typography>
                <Typography variant='body2' sx={{ color: '#ddd', pt: 1, pb: 1 }}>Be aware that the platform is currently under active development.</Typography>

                <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>Creator Features</Typography>
                <Typography variant='body2' sx={{ color: '#ddd', pt: 1, pb: 1 }}>As a creator, you'll have access to tools to enhance your streams and foster your community.</Typography>

                <Accordion sx={{ bgcolor: '#161616', color: 'white' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#fff' }} />} sx={{ alignItems: 'center' }}>
                        <ComputerIcon sx={{ mr: 1, color: '#fff' }} />
                        <Typography>OBS Overlays</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Integrate customizable overlays directly into your OBS setup, enhancing interaction during your live streams.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Overlays include multi-platform live chat, alerts, and decorations with built-in avatars visible.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ bgcolor: '#161616', color: '#fff' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#fff' }} />} sx={{ alignItems: 'center' }}>
                        <CheckroomIcon sx={{ mr: 1, color: '#fff' }} />
                        <Typography>Viewer Avatars and Personalisation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Allow your viewers to create and personalize their own avatars that appear on your stream overlays.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            The platform will reward eligible viewers with XP and coins on the Bloomies platform for engagement and donations such as Twitch bits and gifted subscriptions.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ bgcolor: '#161616', color: 'white' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#fff' }} />} sx={{ alignItems: 'center' }}>
                        <Paid sx={{ mr: 1, color: '#fff' }} />
                        <Typography>Sponsorship Opportunities</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Earn rewards for viewers which register on the bloomies platform and payouts for converted offers which users from your community participate in.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ bgcolor: '#161616', color: '#fff' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#fff' }} />} sx={{ alignItems: 'center' }}>
                        <ShieldIcon sx={{ mr: 1, color: '#fff' }} />
                        <Typography>BloomGuard (Safety Feature)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            BloomGuard provides robust, opt-in safeguarding tools, utilizing AI to detect and manage harmful behavior across multiple streams.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Our real-time moderation system reviews potential risks, ensuring community safety.
                        </Typography>
                        <Typography variant='body2' sx={{ mt: 1, }}>
                            Bans for serious offenses, once confirmed, are enforceable across all participating channels, promoting a safer environment for all creators and users embracing the Bloomies platform.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ backgroundColor: '#77777799', p: 2, mb: 2, mt: 3, }}>
                    <Typography variant='h5' sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <Info sx={{ mr: 1 }} /> Paid Features Planned
                    </Typography>
                    <Typography variant='body2' sx={{ mt: 1}}>
                        The features listed above will always be available to creators free of charge. To support the platform's ongoing costs a paid monthly subscription is planned to offer more advanced functionality, such as multi-platform support, advanced moderation tools and the ability to accept donations from viewers.
                    </Typography>
                </Box>

                <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>Eligibility Guidelines</Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    To join our creator program, you must meet most of the following criteria to ensure your community is active and your content promotes inclusivity:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>Minimum of 250 Twitch followers and an active viewership.</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>Consistent broadcasting with at least 2 streams per week.</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>Content primarily focused on gaming or art.</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>Commitment to uphold platform guidelines and promote respect and safety for all participants.</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: 'small', color: 'primary.main' }} />
                        </ListItemIcon>
                        <Typography variant='body2'>Your account must be in good standing.</Typography>
                    </ListItem>
                </List>

                <Typography variant='body2' sx={{ mt: 1, color: '#fcc' }}>
                    Note: Creators catering to primarily 18+ audiences should be mindful that the bloomies platform caters to users aged 13 and over. Enable 'adult mode' in your creator profile to hide your profile for these users.
                </Typography>

                <Typography variant='body2' sx={{ mt: 1, }}>
                    If you don't meet all of the above guidelines, feel free to apply anyway. We just want to make sure all creators we include on the platform are able to make the best use of the features we're building.
                </Typography>
                <Button href="https://discord.gg/bloomies"
                    sx={{
                        p: 2,
                        pt: 1,
                        mt: 2,
                        zoom: 1.5,
                        pb: 1,
                        bgcolor: '#af5166',
                        color: 'primary.contrastText',
                        '&:hover': {
                            bgcolor: '#d9738a',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                    startIcon={<HowToReg />}
                >
                    Apply Now
                </Button>
                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 23rd April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PagePartnerBecomeACreator;
