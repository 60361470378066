// src/components/NavigationBar.tsx
import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Typography, Button, useTheme, useMediaQuery, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import PeopleIcon from '@mui/icons-material/People';
import NavigationItem from './NavigationItem';
import LayoutContainer from './LayoutContainer';
import SurfaceGlass from '../surfaces/SurfaceGlass';
import { Link } from 'react-router-dom';
import GamepadIcon from '@mui/icons-material/RocketLaunch';
import { useAuth } from '../context/AuthContext';
import { AutoFixHigh } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

interface DiscordLoginPropTypes {
    small: boolean;
}

const DiscordLoginButton: React.FC<DiscordLoginPropTypes> = ({ small }) => {
    return ( <></>);
    return (
        <Tooltip title="Feature disabled during development stages.">
            <Button disabled sx={{ backgroundColor: '#5865F2', p: 1, pl: 2, pr: 2 }} href="/auth/discord">
                <FontAwesomeIcon icon={faDiscord} style={{ marginRight: '0.5em' }} />
                {small ? 'Login' : 'Login with Discord'}
            </Button>
        </Tooltip>

    );
};

const NavigationBar: React.FC = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isLoggedIn = user != null; // Placeholder, replace with actual auth check

    return (
        <AppBar position="static" sx={{ backgroundColor: 'rgba(10,10,10,0.5);', height: '64px', top: 0, position: 'fixed', zIndex: 1000, overflow: 'hidden', }}>
            <Box sx={{ position: 'relative' }}>
                <LayoutContainer>
                    <SurfaceGlass>
                    </SurfaceGlass>
                    <Box paddingLeft={isMobile ? 0 : 2} paddingRight={2}>
                        <Toolbar disableGutters sx={{ alignItems: 'stretch', justifyContent: 'space-between', height: '64px' }}>
                            {!isMobile && (
                                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0, height: '100%' }}>
                                    <Link to={'/'}>
                                        <img src="/assets/logo_banner.png" alt="Bloomies Icon" height="64" />
                                    </Link>
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', flexGrow: 1, ml: 3, alignItems: 'stretch' }}>
                                {isLoggedIn && <NavigationItem to={`/profile/${user?.username}`} icon={<PersonIcon />} label="Profile" />}
                                {isLoggedIn && <NavigationItem to="/shop" icon={<StoreIcon />} label="Shop" />}
                                <NavigationItem to="/community" icon={<PeopleIcon />} label="Community" />
                                {isLoggedIn && <NavigationItem to="/apps" icon={<GamepadIcon />} label="Apps" />}
                                <NavigationItem to="/partners/become_a_creator" icon={<AutoFixHigh />} label="Creator Program" />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {isLoggedIn ? (
                                    <>
                                        <IconButton color="inherit">
                                            <NotificationsIcon />
                                        </IconButton>
                                        <IconButton href="/settings" color="inherit">
                                            <SettingsIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <DiscordLoginButton small={isMobile} />
                                )}
                            </Box>
                        </Toolbar>
                    </Box>

                </LayoutContainer>
            </Box>

        </AppBar>
    );
};

export default NavigationBar;
