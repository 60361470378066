// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import HomePage from './pages/PageHome';
import ProfilePage from './pages/PageProfile';
import CommunityPage from './pages/PageCommunity';
import PageAuth from './pages/PageAuth';
import NotFoundPage from './pages/PageNotFound';
import ProtectedRoute from './components/ProtectedRoute';
import PageComingSoon from './pages/PageComingSoon';
import PagePartner from './pages/PagePartner';
import DevleopmentPage from './pages/PageInDevelopment';
import PageEcho from './pages/PageEcho';
import PageAuthSuccess from './pages/PageAuthSuccess';
import PageSettings from './pages/PageSettings';

const App: React.FC = () => {
    return (
        <div>
            <NavigationBar />
            <Routes>
                <Route path="/auth" element={<PageAuth />} />
                <Route path="/auth/success" element={<PageAuthSuccess />} />
                <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/profile/:username" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}>
                    <Route path="bio" element={<ProfilePage />} />
                    <Route path="friends" element={<ProfilePage />} />
                    <Route path="inventory" element={<ProfilePage />} />
                    <Route path="achievements" element={<ProfilePage />} />
                    <Route path="wardrobe" element={<ProfilePage />} />
                </Route>
                <Route path="/echo/:slug" element={<ProtectedRoute><PageEcho /></ProtectedRoute>}/>
                <Route path="/profile" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/shop" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/register" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/community" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/community/*" element={<ProtectedRoute><CommunityPage /></ProtectedRoute>} />
                <Route path="/partners/*" element={<ProtectedRoute><PagePartner /></ProtectedRoute>} />
                <Route path="/apps" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/settings/*" element={<ProtectedRoute><PageSettings /></ProtectedRoute>} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    );
};

export default App;
