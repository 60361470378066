import React, { useState } from 'react';
import { Box, Drawer, IconButton, Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LayoutContainer from './LayoutContainer';
import SubrouteNav from './SubrouteNav'; // Ensure you import SubrouteNav if it's used directly
import { ChevronLeft } from '@mui/icons-material';

interface SubrouteLayoutProps {
  sidebar: React.ReactElement;
  children: React.ReactNode;
  wide?: boolean;
}

const SubrouteLayout: React.FC<SubrouteLayoutProps> = ({ sidebar, children, wide = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <LayoutContainer>
      <Grid container spacing={3} sx={{ p: 3, pt: '96px' }}>
        {isMobile ? (
          <>
            <Grid item xs={12} sx={{ display: { sm: 'flex', md: 'none' } }}  onClick={handleDrawerToggle}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <ChevronLeft sx={{ color: '#fff' }} />
                <Typography variant='h4' sx={{pl: 2,}}>Back</Typography>
              </IconButton>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', backgroundColor: 'transparent', backdropFilter: 'blur(20px)', marginTop: '64px', p: 4, },
                }}
              >
                {React.cloneElement(sidebar, { onClose: handleDrawerToggle })}
              </Drawer>
            </Grid>
          </>
        ) : (
          <Grid item md={wide ? 4 : 3} sx={{ display: { xs: 'none', md: 'block' } }}>
            {sidebar}
          </Grid>
        )}
        <Grid item xs={12} md={wide ? 8 : 9}>
          {children}
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};

export default SubrouteLayout;
