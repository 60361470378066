// src/utils/profileUtils.ts

/**
 * Calculates the current level based on experience points.
 * This is a simple example; adjust the formula as needed.
 */
export const calculateLevel = (xp: number) => {
    let level = 1;  // Start from level 1
    let xpForNextLevel = 100;  // XP required to reach level 2

    while (xp >= xpForNextLevel) {
        level++;  // Move to the next level
        xpForNextLevel += 50 * level;  // Increase XP requirement for the next level
    }

    return level;
}

/**
 * Converts a Unix timestamp to a relative time string (e.g., "3 months ago").
 */
export const timeAgo = (dateUnix: number): string => {
    const seconds = Math.floor((new Date().getTime() - dateUnix) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
};
