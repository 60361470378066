// src/pages/PageAuth.tsx
import React, { useState } from 'react';
import LoginModal from '../components/LoginModal';

const PageAuth: React.FC = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <LoginModal open={open} onClose={handleClose} />
    </div>
  );
};

export default PageAuth;
