// src/sections/HeroSection.tsx
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';

interface HeroSectionProps {
    backgroundComponent: ReactNode;
    children: ReactNode;
    fill: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({ backgroundComponent, children, fill }) => {
    return (
        <Box component="section" sx={{
            position: 'relative',
            width: '100%',
            height: '60vh', // Default height for larger screens
            minHeight: '500px',
            paddingTop: '64px',
            overflow: 'hidden',
            '@media (max-width:900px)': {
                height: '100vh', // 100vh on mobile devices
                paddingTop: 0, // Optional: Remove padding on top for mobile
            }
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                userSelect: 'none',
                pointerEvents: 'none'
            }}>
                {backgroundComponent}
            </Box>
            {fill ? children :
                <LayoutContainer>
                    <Box>{children}</Box>
                </LayoutContainer>
            }
        </Box>
    );
};

export default HeroSection;
