// src/Root.tsx
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import client from './ApolloClient';
import App from './App';

const Root: React.FC = () => {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </AuthProvider>
        </ApolloProvider>
    );
};

export default Root;
