import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import LayoutContainer from '../components/LayoutContainer';
import ThemeBackground from '../components/ThemeBackground';
import { ProfileTheme } from '../types/ProfileTheme';
import { useNavigate } from 'react-router';

const theme: ProfileTheme = {
    avatarUrl: '/assets/avatar_example.png',
    backgroundUrl: '/assets/theme/tech/background.jpg',
    overlayUrls: [],
    horizontalRuleUrl: '/assets/theme/tech/seperator.jpg',
    backgroundColor: '#110f0d',
    overlayStyles: [],
    fontStyles: {
        title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
        subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
        text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
        para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
    },
    tabStyle: {
        backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
    },
    containerStyle: { backgroundColor: '#1b1c1e' },
    globalStyles: {
        transition: 'opacity 2s',
        opacity: 0,
    },
};

const PageAuthSuccess: React.FC = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            // Redirect after 5 seconds if user is still null
            if (!user) {
                navigate('/');
            }
        }, 5000);

        return () => clearTimeout(timer);
    }, [user, history]);

    let modifiedTheme = { ...theme, globalStyles: { ...theme.globalStyles, opacity: loading ? 0 : 1 } };

    // Array of welcome messages
    const welcomeMessages = [
        'Welcome back,',
        'We\'ve missed you,',
        'Nice to see you again,',
        'Good to see you,'
    ];

    // Select a random welcome message
    const randomIndex = Math.floor(Math.random() * welcomeMessages.length);
    const randomWelcomeMessage = welcomeMessages[randomIndex];

    return (
        <Box component="section" sx={{ background: theme.backgroundColor, color: theme.fontStyles.text.color, position: 'relative', minHeight: '100vh' }}>
            <ThemeBackground theme={modifiedTheme} />
            <Box sx={{ paddingTop: '128px', position: 'relative', filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.9))', }}>
                <LayoutContainer>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        maxWidth: '800px',
                        margin: '0 auto',
                        textAlign: 'center',
                        textShadow: '0px 2px 2px #000'
                    }}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Typography variant='h2' sx={{ mb: 2 }}>Authentication Successful</Typography>
                                <Typography variant='h5' sx={{ mb: 2 }}>{randomWelcomeMessage}</Typography>
                                {user && <Typography variant='body1'>{user.username}</Typography>}
                            </>
                        )}
                    </Box>
                </LayoutContainer>
            </Box>
        </Box>
    );
};

export default PageAuthSuccess;
