// src/pages/PageCommunity.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SubrouteLayout from '../components/SubrouteLayout';
import SubrouteNav from '../components/SubrouteNav';
import PageCommunityLatest from './PageCommunityLatest';
import PageCommunityEvents from './PageCommunityEvents';
import PageCommunityOffers from './PageCommunityOffers';
import PageCommunityUpdates from './PageCommunityUpdates';

const PageCommunity: React.FC = () => {
    const navItems = [
        { label: 'Latest', path: '/community/latest' },
        { label: 'Events', path: '/community/events' },
        { label: 'Offers', path: '/community/offers' },
        { label: 'Updates', path: '/community/updates' },
    ];

    return (
        <SubrouteLayout sidebar={<SubrouteNav routes={navItems} />}>
            <Routes>
                <Route path="/" element={<PageCommunityLatest />} />
                <Route path="latest" element={<PageCommunityLatest />} />
                <Route path="events" element={<PageCommunityEvents />} />
                <Route path="offers" element={<PageCommunityOffers />} />
                <Route path="updates" element={<PageCommunityUpdates />} />
            </Routes>
        </SubrouteLayout>
    );
};

export default PageCommunity;
