// src/components/SubrouteNav.tsx
import React from 'react';
import { List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

interface SubrouteNavProps {
  routes: Array<{
    label: string;
    path: string;
    icon?: JSX.Element;
  }>;
  heading?: JSX.Element;
  onClose?: () => void;
}

const SubrouteNav: React.FC<SubrouteNavProps> = ({ routes, onClose, heading = null }) => {
  const location = useLocation();

  return (
    <Box sx={{
      borderRadius: 3,
      backgroundColor: '#212022',
      p: 2,
      overflow: 'auto',
      position: 'relative',
    }}>
      {heading}
      <List component="nav">
        {routes.map((route, index) => (
          <ListItem
            button
            component={Link}
            to={route.path}
            key={index}
            onClick={onClose}
            selected={location.pathname === route.path}  // Highlight if the route is active
            sx={{
              borderRadius: 3,
              '&.Mui-selected': {
                backgroundColor: '#1a191b',
                color: 'primary.main', // change text color if needed
                '& .MuiListItemText-root': { // Ensures the text also uses the primary color
                  color: 'inherit',
                },
                '&:hover': {
                  backgroundColor: '#0f0f10',
                }
              },
              '&:hover': {
                backgroundColor: '#1a191b',
              }
            }}
          >
            {route.icon ?? null}
            <ListItemText sx={{ pl: route.icon && 1 }} primary={route.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
};

export default SubrouteNav;
