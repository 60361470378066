import { Box, Typography, Button } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { Signpost } from '@mui/icons-material';

const CreatorProgramSection: React.FC = () => {
    return (
        <Box component="section" id="creatorProgram" sx={{ position: 'relative', py: 4 }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                userSelect: 'none',
                pointerEvents: 'none',
                backgroundImage: "url('/assets/banners/banner_creators.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            </Box>
            <LayoutContainer>
                <Box sx={{ p: 4, position: 'relative' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 2, color: 'primary.main' }}>
                        Are you a creator?
                    </Typography>
                    <Typography variant="body1" sx={{ margin: '0 auto', textAlign: 'center', mb: 3, maxWidth: { md: '80%', xs: 'auto' } }}>
                        Register your interest in our creator program for access to exclusive features and safety tools, enabling new avenues of self expression for your community and the opportunity earn platform rewards for participating in your broadcasts.
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button href="/partners/become_a_creator"
                            sx={{
                                p: 2,
                                pt: 1,
                                pb: 1,
                                bgcolor: '#af5166',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    bgcolor: '#d9738a',
                                    boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                },
                            }}
                            startIcon={<Signpost />}
                        >
                            Register your interest
                        </Button>
                    </Box>
                </Box>

            </LayoutContainer>
        </Box>
    );
};

export default CreatorProgramSection;
