// src/pages/community/PageCommunityLatest.tsx
import React from 'react';
import { Typography } from '@mui/material';

const PageCommunityLatest: React.FC = () => {
    return (
        <div>
            <Typography variant="h2">What's New?</Typography>
            {/* Additional content here */}
        </div>
    );
};

export default PageCommunityLatest;