// src/components/NavigationItem.tsx
import React from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavigationItemProps {
  to: string;
  icon: React.ReactElement;
  label: string;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ to, icon, label }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Clone the icon with new props to adjust size
  const largeIcon = React.cloneElement(icon, {
    style: { fontSize: theme.typography.h4.fontSize } // or use a custom size like '32px'
  });

  return (
    <Button
      color="inherit"
      component={Link}
      to={to}
      startIcon={!isMobile ? largeIcon : null} // Show larger icon only on non-mobile views
      sx={{
        height: '100%',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 0,
        lineHeight: '64px',
        paddingLeft: isMobile ? 1: 3,
        paddingRight: isMobile ? 1: 3,
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }
      }}
    >
      {isMobile && largeIcon}
      <Typography variant={isMobile ? 'caption' : 'body1'} sx={{ ml: isMobile ? 0 : 0 }}>
        {label}
      </Typography>
    </Button>
  );
};

export default NavigationItem;
