import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayoutContainer from '../components/LayoutContainer';

const PageEcho: React.FC = () => {
    const [content, setContent] = useState<React.ReactNode>(null);

    useEffect(() => {
        const echoContentDiv = document.getElementById("echo");
        if (echoContentDiv && echoContentDiv.innerHTML !== 'none') {
            // Parse the inner HTML and convert it to Material-UI components
            const transformedContent = transformContent(echoContentDiv);
            setContent(transformedContent);
        }
    }, []);

    const transformContent = (element: HTMLElement): React.ReactNode => {
        const childNodes = Array.from(element.childNodes); // Convert NodeList to array
        return childNodes.map((node, index) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                const elemNode = node as HTMLElement;
                
                switch (elemNode.tagName.toLowerCase()) {
                    case 'h1':
                        return <Typography key={index} variant="h1"  sx={{ mt: 4, mb: 4 }}>{elemNode.textContent}</Typography>;
                    case 'h2':
                        return <Typography key={index} sx={{ mt: 3, mb: 3 }} variant="h2">{elemNode.textContent}</Typography>;
                    case 'h3':
                        return <Typography key={index} variant="h3" sx={{ mt: 2, mb: 2 }}>{elemNode.textContent}</Typography>;
                    case 'p':
                        return <Typography key={index} paragraph sx={{ mt: 1, mb: 1 }}>{elemNode.textContent}</Typography>;
                    case 'ul':
                        return (
                            <ul key={index}>
                                {Array.from(elemNode.children).map((li, liIndex) => (
                                    <li key={liIndex}>{li.textContent}</li>
                                ))}
                            </ul>
                        );
                    case 'div':
                        // Recursively transform nested divs or other elements
                        return <div key={index}>{transformContent(elemNode)}</div>;
                    default:
                        return <span key={index}>{elemNode.textContent}</span>;
                }
            }
            return null;
        });
    };

    return (
        <Box component="section" sx={{ position: 'relative', paddingTop: '80px' }}>
            <LayoutContainer>
                <Box sx={{ position: 'relative', p: 4 }}>
                {content || <Typography variant="body1">No Echo content available.</Typography>}
                </Box>
                <Box sx={{ backgroundColor: 'rgba(255, 40, 40, 0.2)', backdropFilter: 'blur(6px)', m: 2, p: 2, textAlign: 'center'}}>
                    <Typography variant="body2">Please be aware that this content is AI-generated.</Typography>
                    <Typography variant="body2">The system can make mistakes, no information presented is claimed to be accurate.</Typography>
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageEcho
