import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import { Box, Link, Typography } from '@mui/material';
import ThemeBackground from '../components/ThemeBackground';
import { ProfileTheme } from '../types/ProfileTheme';

const theme: ProfileTheme = {
  avatarUrl: '/assets/avatar_example.png',
  backgroundUrl: '/assets/theme/tech/background.jpg',
  overlayUrls: [],
  horizontalRuleUrl: '/assets/theme/tech/seperator.jpg',
  backgroundColor: '#110f0d',
  overlayStyles: [],
  fontStyles: {
    title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
    subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
    text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
    para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
  },
  tabStyle: {
    backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
  },
  containerStyle: { backgroundColor: '#1b1c1e' },
  globalStyles: {
  },
};

const DevleopmentPage: React.FC = () => {
  return (
    <Box component="section" sx={{ background: theme.backgroundColor, color: theme.fontStyles.text.color, position: 'relative', minHeight: '100vh' }}>
      <ThemeBackground theme={theme} />
      <Box sx={{ paddingTop: '128px', position: 'relative', filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.9))', }}>
        <LayoutContainer>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
            textShadow: '0px 2px 2px #000'
          }}>
            <Typography variant='h2' sx={{ mb: 2 }}>Under Development</Typography>
            <Typography variant='h5' sx={{ mb: 2 }}>This page is hidden because it's not yet ready.</Typography>
            <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>For more details on feature launches including development status, check the <Link href="https://discord.gg/bloomies" >official discord</Link> or email support@everbloom.media</Typography>
          </Box>

        </LayoutContainer>
      </Box>
    </Box>
  );
};

export default DevleopmentPage;