// src/components/AchievementBadge.tsx
import React from 'react';
import { Tooltip, Box, Typography } from '@mui/material';
import { ProfileAchievement } from '../types/ProfileAchievement';
import { timeAgo } from '../utils/ProfileUtils';

interface AchievementBadgeProps {
    achievement: ProfileAchievement;
    size: number; // pixel width of the badge
}

const AchievementBadge: React.FC<AchievementBadgeProps> = ({ achievement, size }) => {
    return (
        <Tooltip sx={{
            backgroundColor: 'rgba(0,0,0,0.6)',
        }} title={
            <>
                <Typography variant={"h5"} color="inherit">{achievement.title}</Typography>
                <Typography>{timeAgo(achievement.earnedAt)}</Typography>
                <Typography>{achievement.description}</Typography>
            </>
        } placement="top">
            <Box
                sx={{
                    width: size,
                    height: size,
                    padding: '8px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                    '&:hover': {  backgroundColor: 'rgba(0,0,0,0.2)', }
                }}
            >
                <Box component="img" src={achievement.imageUrl} sx={{
                    width: size,
                    height: size,
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'scale(1.1)' }
                }} />
            </Box>
        </Tooltip>
    );
};

export default AchievementBadge;
