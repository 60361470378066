// src/components/LayoutContainer.tsx
import React from 'react';
import { Box } from '@mui/material';

interface LayoutContainerProps {
  children: React.ReactNode;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({ children }) => {
  return (
    <Box sx={{
      maxWidth: 1200,
      mx: 'auto',
      width: '100%',
      overflow: 'hidden',
    }}>
      {children}
    </Box>
  );
};

export default LayoutContainer;
