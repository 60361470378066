// src/context/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

type User = {
  id: number;
  username: string;
  discord_alias: string;
  role: string;
  email: string;
  discord_uid: string;
  date_joined: string;
};

type AuthContextType = {
  user: User | null;
  login: (userDetails: User) => void;
  logout: () => void;
};

interface CustomWindow extends Window {
  bloomies?: { user: User };
}

declare let window: CustomWindow;


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const authCache = localStorage.getItem('auth');
  if (authCache) {
    try {
      const user = JSON.parse(authCache);
      if (user) {
        window.bloomies = { user };
      }
    } catch (error) {
      console.error('Error parsing user data from localStorage:', error);
    }
  }
  const bloomiesUser = window.bloomies?.user ?? authCache as unknown as User ?? null;
  const [user, setUser] = useState<User | null>(bloomiesUser ?? null);

  useEffect(() => {
    // Check if the user data is available in window.bloomies
    if (bloomiesUser) {
      setUser(bloomiesUser);
    } else {
      // Fetch user info from the API as fallback
      axios.get('/api/users/current').then(response => {
        localStorage.setItem('auth', JSON.stringify(response.data));
        setUser(response.data);
      }).catch(() => {
        localStorage.removeItem('auth');
        setUser(null);
      });
    }
  }, []);

  const login = (userDetails: User) => {
    setUser(userDetails);
    // Additional login logic here
  };

  const logout = () => {
    setUser(null);
    // Additional logout logic here
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
