// src/sections/ProfileSection.tsx
import React from 'react';
import { Box } from '@mui/material';
import { ProfileTheme } from '../types/ProfileTheme';


interface ThemeBackgroundProps {
    theme: ProfileTheme;
}

const ThemeBackground: React.FC<ThemeBackgroundProps> = ({theme}) => {
    return (
        <Box sx={{
            ...theme.globalStyles,
            position: 'absolute',
            display: 'block',
            minHeight: '900px',
            width: '100%',
        }}>
            <Box sx={{
                backgroundImage: `url(${theme.backgroundUrl})`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: 'center',
                height: 640,
                position: 'relative',
                textAlign: 'center',
            }}>
                {/* Overlays and animations */}
                {theme.overlayUrls.map((url, index) => (
                    <Box key={index} sx={{
                        ...theme.overlayStyles[index],
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${url})`,
                        backgroundPosition: 'center',
                    }} />
                ))}
                {/* Content container */}

            </Box>
            <Box sx={{
                backgroundImage: `url(${theme.horizontalRuleUrl})`,
                height: 256,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: 'center',
                position: 'relative',
                width: '100%'
            }} />
            <style>
                {`
                @keyframes parallax {
                    from { background-position: 0px 0px; }
                    to { background-position: 1920px 0px; }
                }
                @keyframes parallax-reversed {
                    from { background-position: 1920px 0px; }
                    to { background-position: 0px 0px; }
                }
                `}
            </style>
        </Box>
    )
}

export default ThemeBackground;