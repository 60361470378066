import { Box, Typography } from '@mui/material';
import React from 'react';
import LayoutContainer from '../components/LayoutContainer';
import SecurityIcon from '@mui/icons-material/Security'; // Icon for security
import GroupIcon from '@mui/icons-material/Group'; // Icon for community
import MoneyOffIcon from '@mui/icons-material/MoneyOff'; // Icon for costs
import { AccountBalance, BugReport, People, Policy } from '@mui/icons-material';

const PagePartnerPlatformGuidelines: React.FC = () => {
    return (
        <Box component="section" sx={{ position: 'relative', color: '#fff' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Platform Guidelines</Typography>
                <Typography variant='h5' sx={{ mb: 2 }}>For Creators and Partners</Typography>
                <Typography variant='body2' sx={{ pt: 1, pb: 1 }}>To maintain a safe and supportive environment:</Typography>


                <Typography variant='h6' sx={{ mt: 2 }}>
                    <SecurityIcon sx={{ verticalAlign: 'middle', color: '#fff' }} /> Trust and Safety
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    As a trusted member of the Bloomies platform, you are expected to foster an inclusive and safe environment for all users. This includes adhering to Twitch and Discord terms of service, especially concerning adult content. Adult Mode must be enabled for creators who broadcast 18+ streams to protect younger viewers.
                </Typography>

                <Typography variant='h6' sx={{ mt: 2 }}>
                    <GroupIcon sx={{ verticalAlign: 'middle', color: '#fff' }} /> Community Conduct
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Hateful conduct or promoting non-inclusive behavior will result in immediate removal from the platform. The Bloomies network supports LGBTQ individuals and other minorities, ensuring a harassment-free experience for everyone.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2 }}>
                    <Policy sx={{ verticalAlign: 'middle', color: '#fff' }} /> Content Policy
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Creators are granted the ability to customize and brand their presence on the platform. However, it is crucial that all branding remains appropriate and does not include excessive gore, adult themes, or any content that could be deemed overly explicit, especially in profiles viewable by users under 18. Creators with 'Adult Mode' enabled must still ensure their profiles and content do not violate platform standards.
                </Typography>

                <Typography variant='h6' sx={{ mt: 2 }}>
                    <BugReport sx={{ verticalAlign: 'middle', color: '#fff' }} /> Exploits
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Creators must respect the platform's operational integrity and refrain from exploiting or encouraging the exploitation of any features. If a functionality issue is identified, it is the responsibility of the creator to report it to the Bloomies team promptly for resolution. This cooperation ensures a fair and stable environment for all users.
                </Typography>


                <Typography variant='h6' sx={{ mt: 2 }}>
                    <MoneyOffIcon sx={{ verticalAlign: 'middle', color: '#fff' }} /> Costs and Fees
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Some features, such as BloomGuard, incur significant costs, and thus may require fees. No refunds for any one-time fees or subscriptions will be provided if a creator is removed for violating guidelines.
                </Typography>

                <Typography variant='body2' sx={{ mt: 1 }}>
                    Creators are not affiliated with Everbloom Media but are distinguished users of the platform. Misuse of platform tools, such as attempting to circumvent BloomGuard, will lead to removal from the network.
                </Typography>

                <Typography variant='h6' sx={{ mt: 2 }}>
                    <AccountBalance sx={{ verticalAlign: 'middle', color: '#fff' }} /> Earning Opportunities for Creators
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    Creators with larger audiences can earn payouts by referring new users to the platform and through user completion of offers available via Bloomies. This program is currently invite-only and requires creators to meet specific engagement thresholds to qualify. A dedicated relationship manager will be assigned to eligible creators to facilitate this process.
                </Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    At present, payout opportunities are exclusively available to creators residing in the US or the UK. Creators interested in participating in this program are encouraged to actively engage with their audience and enhance their platform presence to meet the eligibility criteria.
                </Typography>

                <Typography variant='body2' sx={{ mt: 2, color: '#888' }}>
                    Last updated 23rd April 2024
                </Typography>
            </LayoutContainer>
        </Box>
    );
};

export default PagePartnerPlatformGuidelines;
