// src/components/SurfaceGlass.tsx
import React from 'react';

interface SurfaceGlassProps {
  children?: React.ReactNode;
}

const SurfaceGlass: React.FC<SurfaceGlassProps> = ({ children }) => {
  return (
    <div style={{
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(8px)',
      background: 'linear-gradient(to top, rgba(12, 12, 12, 0.2), rgba(166, 166, 166, 0.2))',
      userSelect: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      {children}
    </div>
  );
};

export default SurfaceGlass;
