// src/pages/community/PageCommunityUpdates.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';

const PageCommunityUpdates: React.FC = () => {
    return (
        <LayoutContainer>
            <Box sx={{ py: 4 }}>
                <Typography variant="h2" gutterBottom>Community Updates</Typography>
                <Typography variant="body1">
                    Stay up to date with all the latest news and updates happening in our community!
                </Typography>
                {/* Similar to offers, here you could list news items or updates */}
            </Box>
        </LayoutContainer>
    );
};

export default PageCommunityUpdates;
