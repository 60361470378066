// src/pages/PageCommunity.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SubrouteLayout from '../components/SubrouteLayout';
import SubrouteNav from '../components/SubrouteNav';
import PagePartnerBecomeACreator from './PagePartnerBecomeACreator';
import PagePartnerPlatformGuidelines from './PagePartnerPlatformGuidelines';
import PageSubscriptionTiers from './PageSubscriptionTiers';
import PageLookingForArtists from './PageLookingForArtists';
import PagePartnerBecomeAnArtist from './PagePartnerBecomeAnArtist';
import PagePartnerArtistProgramPaymentTerms from './PagePartnerArtistProgramPaymentTerms';

const PagePartner: React.FC = () => {
    const navItems = [
        { label: 'Become a partner', path: '/partners/become_a_creator' },
        { label: 'Become an artist', path: '/partners/become_an_artist' },
        { label: 'Creator Tools', path: '/partners/creator_tools_tiers' },
        { label: 'Platform guidelines', path: '/partners/platform_guidelines' },
        { label: 'Artistic guidelines', path: '/partners/looking_for_artists' },
        { label: 'Artist payment terms', path: '/partners/artist_payment_terms' }
    ];

    return (
        <SubrouteLayout sidebar={<SubrouteNav routes={navItems}  />}>
            <Routes>
                <Route path="become_a_creator" element={<PagePartnerBecomeACreator />} />
                <Route path="platform_guidelines" element={<PagePartnerPlatformGuidelines />} />
                <Route path="creator_tools_tiers" element={<PageSubscriptionTiers />} />
                <Route path="looking_for_artists" element={<PageLookingForArtists />} />
                <Route path="become_an_artist" element={<PagePartnerBecomeAnArtist />} />
                <Route path="artist_payment_terms" element={<PagePartnerArtistProgramPaymentTerms/>} />
            </Routes>
        </SubrouteLayout>
    );
};

export default PagePartner;
