// Example usage in src/pages/HomePage.tsx or a similar file
import React from 'react';
import HeroSection from '../sections/HeroSection';
import { Box, Button, Link, Typography } from '@mui/material';
import BloomRenderer, { BloomRendererScene, BloomRendererSceneElementType } from '../components/BloomRenderer';
import LayoutContainer from '../components/LayoutContainer';
import { Book, Build, Games, Loyalty, RocketRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import HowItWorksSection from '../sections/HowItWorks';
import WhatsNewSection from '../sections/WhatsNewHomepage';
import CreatorProgramSection from '../sections/CreatorProgramSection';

const ThreeColumnSection: React.FC = () => {
    return (
        <Box component="section" sx={{backgroundColor: '#110f12' }}>

            <LayoutContainer>
                <Grid container spacing={2} sx={{ padding: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Loyalty sx={{ fontSize: 40, color: '#fff' }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>Earn Rewards</Typography>
                            <Typography variant="body2" color="contrastText">
                                Gain XP and coins by for participation on streams and Discord. Customize your avatar and see it come to life!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Build sx={{ fontSize: 40,color: '#fff'  }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>Tools for Creators</Typography>
                            <Typography variant="body2" color="contrastText">
                                Enhance your streaming experience with our upcoming suite of tools designed to engage and grow your community.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Games sx={{ fontSize: 40,color: '#fff'  }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>Play and Connect</Typography>
                            <Typography variant="body2" color="contrastText">
                                Access exclusive giveaways, games and experiences directly through the Bloomies platform.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </LayoutContainer>

        </Box>
    );
};

const FooterSection: React.FC = () => {
    return (
        <LayoutContainer>
            <Box sx={{ textAlign: 'center', padding: 4 }}>
                <Box sx={{ display: 'none', justifyContent: 'center', gap: 2, mt: 1, padding: 4, }}>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Privacy Policy</Typography></Link>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Sponsorships</Typography></Link>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Careers</Typography></Link>
                </Box>
                <Typography variant="body2" color="#888">
                    Bloomies is a platform and brand-concept owned and operated by Everbloom Media Ltd, registered in the UK (Company No. 14974080).
                </Typography>
                <Typography variant="body2" color="#888">
                    Please note that some promotional material on this site features AI-generated art, whereas all avatar assets are lovingly crafted by talented artists.
                </Typography>

            </Box>
        </LayoutContainer>
    );
};



const HomePage: React.FC = () => {
    const scene: BloomRendererScene = {
        elements: [{
            type: BloomRendererSceneElementType.Image,
            x: 0,
            y: 0,
            imageUrl: '/assets/scenes/blossom_hills/background.jpg',
            size: 0,
            _width: 0,
            _height: 0,
            yj: -1,
            xm: 3,
            ym: 2,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: 150,
            y: 0,
            imageUrl: '/assets/scenes/blossom_hills/background2.png',
            size: 0,
            _width: 0,
            _height: 0,
            xj: 1,
            yj: -2,
            xm: 6,
            ym: 4,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: 285,
            y: 190,
            imageUrl: '/assets/scenes/blossom_hills/background1.png',
            size: 0,
            _width: 0,
            _height: 0,
            xj: -1,
            yj: -3,
            xm: 9,
            ym: 6,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: 0,
            y: 140,
            imageUrl: '/assets/scenes/blossom_hills/foreground.png',
            size: 0,
            _width: 0,
            _height: 0,
            xj: 1,
            yj: 5,
            xm: 24,
            ym: 14,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: -500,
            y: -80,
            imageUrl: '/assets/scenes/blossom_hills/sakura1.png',
            size: 0,
            _width: 0,
            _height: 0,
            yj: 4,
            xj: -2,
            xm: 20,
            ym: 12,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: 692,
            y: -46,
            imageUrl: '/assets/scenes/blossom_hills/sakura2.png',
            size: 0,
            yj: 4,
            xj: -1,
            _width: 0,
            _height: 0,
            xm: 20,
            ym: 12,
        }
        ]
    };
    const background = <BloomRenderer scene={scene} zoom={0.52} width={930} height={300} sx={{ filter: 'brightness(0.75)' }}></BloomRenderer>
    const buttonStyle: React.CSSProperties = {
        margin: 1,
        padding: '8px 15px',
        width: '180px',
        fontSize: '20px',
        borderRadius: 0,
        boxShadow: '0px 2px 2px rgba(0,0,0,0.2)'
    }
    return (
        <><HeroSection backgroundComponent={background} fill={true}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                position: 'relative',
                filter: 'drop-shadow(0px 10px 40px rgba(255, 255, 255, 0.6))',
            }} id="flexHero">
                <Box sx={{
                    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.8)',
                    margin: '0 auto',
                    padding: 4,
                    borderRadius: 8,
                    userSelect: 'none',
                }}>
                    <LayoutContainer>
                        <Box sx={{
                            padding: 3,
                            filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.5))',
                        }}>
                            <Typography variant="heroSub" color="primary.contrastText" align="center">
                                Connect with fellow bloomies and creators.
                            </Typography>
                            <Typography variant="heroSub" color="primary.contrastText" align="center">
                                Join now to customize your avatar and earn rewards!
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center', // Centers the buttons horizontally
                            mt: 0, // Adds margin top for spacing from text above
                        }}>
                            <Button
                                sx={{
                                    ...buttonStyle,
                                    bgcolor: '#3d3d3d',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        bgcolor: '#626564',
                                        boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                    },
                                }}
                                startIcon={<Book />}
                                href="#howItWorks"
                            >
                                Learn more
                            </Button>
                            <Button

                              href="/register"
                                sx={{
                                    ...buttonStyle,
                                    display: 'none',
                                    bgcolor: '#af5166',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        bgcolor: '#d9738a',
                                        boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                    },
                                }}
                                startIcon={<RocketRounded />}
                            >
                                Sign up
                            </Button>
                        </Box>
                    </LayoutContainer>

                </Box>
            </Box>
        </HeroSection>
        <ThreeColumnSection></ThreeColumnSection>
        <HowItWorksSection></HowItWorksSection>
        <WhatsNewSection></WhatsNewSection>
        <CreatorProgramSection></CreatorProgramSection>
        <FooterSection></FooterSection>
        </>
    );

};

export default HomePage;
